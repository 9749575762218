import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Link } from 'gatsby'
import Testimonials from '../components/Testimonials'
import BlogRoll from '../components/BlogRoll'
const item4 = {image: "/img/CAV1.png"}
const item5 = {image: "/img/Picture1.png"}
const item6 = {image: "/img/city-2.png"}
const publication1 = {image: "/img/AIpaper1.png"}
const publication2 = {image: "/img/frank-GCQ.gif"}
const publication3 = {image: "/img/Paul-research.gif"}
const publication4 = {image: "/img/crash_avoidance.gif"}
const publication5 = {image: "/img/yujie-research.gif"}
const publication6 = {image: "/img/sibling.png"}
const publication7 = {image: "/img/pcp.png"}
const publication8 = {image: "/img/skycomputer.png"}
export const ProductPageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  testimonials,
  fullImage,
  pricing,
}) => (
  <div className="content">
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
      }}
    >
      <h2
        className="has-text-weight-bold is-size-1"
        style={{
          boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
          backgroundColor: '#f40',
          color: 'white',
          padding: '1rem',
        }}
      >
        {title}
      </h2>
    </div>
    <section className="section section--gradient" style ={{padding:'0rem 1.5rem'}}>
      <div className="container">
        <div className="section" style ={{padding:'1rem 1.5rem'}}> 
          <div className="columns">
            <div className="column is-7 is-offset-1" style ={{padding:'0rem'}}>
              <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
              <p>{description}</p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              {/* <Features gridItems={intro.blurbs} /> */}
              <div className="columns">
                
                  <h3 className="has-text-weight-semibold is-size-3">
                    {main.heading}
                  </h3>
                  <p>{main.description}</p>
                
              </div>
              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child">
                      <div
                      style={{
                        width: '343px',
                        height: '229px',
                        display: 'inline-block',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={main.image1} />
                      </div>
                        Artificial Intelligence and Machine Learning (AI/ML)
                        
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child">
                      <div
                      style={{
                        width: '343px',
                        height: '229px',
                        display: 'inline-block',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={main.image2} />
                      </div>  
                        Control Theory
                        
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child">
                      <div
                      style={{
                        width: '343px',
                        height: '229px',
                        display: 'inline-block',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={main.image3} />
                      </div> 
                        Internet of Things (IoT)
                       
                      </article>
                    </div>
                    
                    
                  </div>
                  
                </div>
              </div>

              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child">
                      <div
                      style={{
                        width: '343px',
                        height: '229px',
                        display: 'inline-block',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={item4} />
                      </div> 
                        Connected and Autonomous Vehicles
                        
                      </article>
                    </div>
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child">
                        <PreviewCompatibleImage imageInfo={item5} />
                        
                        Human Factors
                        
                      </article>
                    </div>
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child">
                      <div
                      style={{
                        width: '343px',
                        height: '229px',
                        display: 'inline-block',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={item6} />
                      </div>
                        Transportation and Infrastructure Systems
                        
                      </article>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="columns">
            
              <h3 className="has-text-weight-semibold is-size-2">Selected Publications</h3>
              <p>{description}</p>
              </div>
              <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={publication2}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{padding:'0.5rem'}}>
                      <h1 className="title is-size-4" style = {{color: '#D64000'}}>
                      Graph neural network and reinforcement learning for multi-agent cooperative control of connected autonomous vehicles
                      </h1>
                      Computer-Aided Civil and Infrastructure Engineering, 2021<br></br>

                      <Link className="group-child" to="https://doi.org/10.1111/mice.12702">
                        [HTML]
                      </Link>
                        
                        
                        </p>
                      </article>
                    </div>
                    

                  </div>

                  
                </div>

               <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={publication7}/>
                                        
                       
                      </article>
                    </div>

                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{padding:'0.5rem'}}>
                      <h1 className="title is-size-4" style = {{color: '#D64000'}}>
                      Space-weighted information fusion using deep reinforcement learning: The context of tactical control
                      of lane-changing autonomous vehicles and connectivity range assessment
                      </h1>
                      Transportation Research Part C: Emerging Technologies, 2021<br></br>
                      
                      <Link className="group-child" to="https://doi.org/10.1016/j.trc.2021.103192">
                        [HTML]
                      </Link>
                        
                        </p>
                      </article>
                    </div>


                  </div>

                  
                </div> 

              <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={publication8}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{padding:'0.5rem'}}>
                      <h1 className="title is-size-4" style = {{color: '#D64000'}}>
                      A deep learning algorithm for simulating autonomous driving considering prior knowledge and temporal 
                      information
                      </h1>
                      Computer-Aided Civil and Infrastructure Engineering, 2020<br></br>
                        
                        <Link className="group-child" to="https://doi.org/10.1111/mice.12495">
                        [HTML]
                        </Link>
                        </p>
                      </article>
                    </div>
                    

                  </div>

                  
                </div> 

              <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={publication1}/>
                                        
                       
                      </article>
                    </div>

                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{padding:'0.5rem'}}>
                      <h1 className="title is-size-4" style = {{color: '#D64000'}}>
                      Spatio-weighted information fusion and DRL-based control for connected autonomous vehicles
                      </h1>
                      IEEE International Conference on Intelligent Transportation Systems (ITSC), 2020<br></br>
                        
                        <Link className="group-child" to="https://ieeexplore.ieee.org/abstract/document/9294550">
                        [HTML]
                        </Link>
                        </p>
                      </article>
                    </div>


                  </div>

                  
                </div>
                

                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={publication6}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{padding:'0.5rem'}}>
                      <h1 className="title is-size-4" style = {{color: '#D64000'}}>
                      Vehicle Connectivity and Automation: A Sibling Relationship
                      </h1>
                      Frontiers in Built Environment, 2020<br></br>
                        
                        <Link className="group-child" to="https://doi.org/10.3389/fbuil.2020.590036">
                        [HTML]
                        </Link>
                        </p>
                      </article>
                    </div>
                    

                  </div>

                  
                </div> 



                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={publication3}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{padding:'0.5rem'}}>
                      <h1 className="title is-size-4" style = {{color: '#D64000'}}>
                      Leveraging the capabilities of connected and autonomous vehicles and multi-agent reinforcement learning to mitigate highway bottleneck congestion
                      </h1>
                      Under review: Transportation Research Part C<br></br>
                        
                        <Link className="group-child" to="https://arxiv.org/abs/2010.05436">
                        [HTML]
                        </Link>
                        </p>
                      </article>
                    </div>
                    

                  </div>

                  
                </div> 
                
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={publication4}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{padding:'0.5rem'}}>
                      <h1 className="title is-size-4" style = {{color: '#D64000'}}>
                      Collision Avoidance Framework for Autonomous Vehicles under Crash Imminent Situations
                      </h1>
                      Master Thesis, Purdue University – West Lafayette, 2020<br></br>
                        
                        <Link className="group-child" to="https://hammer.figshare.com/articles/thesis/COLLISION_AVOIDANCE_FRAMEWORK_FOR_AUTONOMOUS_VEHICLES_UNDER_CRASH_IMMINENT_SITUATIONS/13369052">
                        [HTML]
                        </Link>
                        </p>
                      </article>
                    </div>
                    

                  </div>

                  
                </div> 
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={publication5}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{padding:'0.5rem'}}>
                      <h1 className="title is-size-4" style = {{color: '#D64000'}}>
                      Using Empirical Trajectory Data to Design Connected Autonomous Vehicle Controllers for Traffic Stabilization
                      </h1>
                      Under review: Transportation Research Part C<br></br>
                        
                        <Link className="group-child" to="https://arxiv.org/abs/2010.05440">
                        [HTML]
                        </Link>
                        </p>
                      </article>
                    </div>
                    

                  </div>

                  
                </div>
                
              
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  )
}

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProductPage

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
        main {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        testimonials {
          author
          quote
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
          }
        }
      }
    }
  }
`
